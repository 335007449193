<template>
  <v-row justify="center" class="mt-5">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <v-card-text>
          <h1 class="display-1">{{ login ? 'Login' : 'Register' }}</h1>
        </v-card-text>

        <v-card-text>
          <v-text-field
            ref="email"
            v-model="email"
            :rules="rules.emailRules"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            ref="password"
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show = !show"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="teal accent-4"
            class="mr-2 white--text"
            text
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <div class="subheading font-weight-regular pl-2">
            {{ login ? 'No account? You can' : 'Already have an account' }}
            <span
              class="red--text register-btn"
              @click="
                login = !login
                resetForm()
              "
              >{{ login ? 'Login' : 'Register' }}</span
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    login: true,
    errorMessages: '',
    formHasErrors: false,
    show: false,
    email: '',
    password: '',
    rules: {
      required: (value) => {
        return !!value || 'Required.'
      },
      min: (v) => {
        return v?.length >= 8 || 'Min 8 characters'
      },
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
  }),

  computed: {
    form() {
      return {
        email: this.email,
        password: this.password,
      }
    },
  },

  watch: {},

  methods: {
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset()
      })
    },
    submit() {
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        if (!this.$refs[f].validate(true)) {
          return
        }
      })

      if (!this.formHasErrors) {
        this.$store.dispatch('setAuth', true)
        this.$router.push('Courses')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin: auto;
}
.no-background-hover::before {
  background-color: transparent !important;
}

.register-btn {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s;
}
.register-btn:hover {
  opacity: 1;
}
</style>
